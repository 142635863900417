import React, { useState } from 'react';
import qs from 'qs';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { func, node, object, shape, string, bool } from 'prop-types';

import { listingProductType } from '../../../../config/configSearch';
import { FormattedMessage, injectIntl } from '../../../../util/reactIntl';

import Field, { hasDataInFields } from '../../Field';
import { Button, Modal, NamedLink } from '../../../../components';
import BlockContainer from '../BlockContainer';

import { isScrollingDisabled, manageDisableScrolling } from '../../../../ducks/ui.duck';

import css from './BlockDefault.module.css';

const indexName = process.env.REACT_APP_ALGOLIA_LISTING_INDEX;

const FieldMedia = props => {
  const { className, media, sizes, options } = props;


  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const BlockDefaultComponent = props => {
  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
    scrollingDisabled,
    onManageDisableScrolling,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);

  const listingProductModal = (
    <Modal
      id="loginFormRequriedModal"
      isOpen={isProductModalOpen}
      onClose={() => {
        setIsProductModalOpen(false);
      }}
      usePortal
      contentClassName={css.modalContent}
      onManageDisableScrolling={onManageDisableScrolling}
      className={css.productTypesModal}
    >
      <div>

        <div className={css.productTypes}>
          <h4 className={css.productTypesTitle}>
            <FormattedMessage id="LoginRequiredPage.modalHeading" />
          </h4>
          <div className={css.productTypesWrapper}>
            {listingProductType.map(type => {
              return (
                <div
                  key={type.key}
                  className={css.categories}
                  onClick={() => {
                    window.open(`${window.location.pathname}search?${qs.stringify({ [indexName]: { refinementList: { productType: [type.label] } } })}`, '_self');
                  }}
                >
                  <img src={type.image} />
                  <br />
                  <div className={css.productName}>{type.label}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );

  return (
    <BlockContainer id={blockId} className={classes}>
      <FieldMedia
        media={media}
        sizes={responsiveImageSizes}
        className={mediaClassName}
        options={options}
      />

      {hasTextComponentFields ? (
        <div className={classNames(textClassName, css.text)}>
          <Field data={title} options={options} />
          <Field data={text} options={options} />
          {blockId == "hero-left" ? <div>
            {listingProductModal}
            <Button onClick={() => {
              setIsProductModalOpen(true)
            }} className={css.shopBtn}>
              <FormattedMessage id="LandingPage.shopNow" />
            </Button>
            <NamedLink name="LoginRequiredPage" className={css.shopBtn}>
              Sell Now
            </NamedLink>
          </div> : null}
          <Field data={callToAction} className={ctaButtonClass} options={options} />
        </div>
      ) : null}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockDefaultComponent.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockDefaultComponent.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // routeConfiguration: arrayOf(propTypes.route).isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const BlockDefault = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl,
  withRouter
)(BlockDefaultComponent);


export default BlockDefault;
